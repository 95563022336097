@font-face {
  font-family: "Clancy";
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/clancy/Clancy-Light.woff2) format("woff2"), url(./fonts/clancy/Clancy-Light.woff) format("woff");
}
@font-face {
  font-family: "Clancy";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/clancy/Clancy-Regular.woff2) format("woff2"), url(./fonts/clancy/Clancy-Regular.woff) format("woff");
}
@font-face {
  font-family: "Clancy";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/clancy/Clancy-Bold.woff2) format("woff2"), url(./fonts/clancy/Clancy-Bold.woff) format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/roboto/Roboto-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/roboto-mono/RobotoMono-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/roboto-mono/RobotoMono-Bold.ttf) format("truetype");
}

@-webkit-keyframes spin {
  from {
   transform:rotate(0)
  }
  to {
   transform:rotate(360deg)
  }
 }
 @keyframes spin {
  from {
   transform:rotate(0)
  }
  to {
   transform:rotate(360deg)
  }
 }

:root {
  --bs-link-color: #3f61c4;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control {
  font-size: 1.25rem;
  border-radius: 0;
  color: #000;
  border: 1px solid #000;
  padding: 0.55rem 1rem;
  font-weight: 600;
  font-family: "Roboto Mono";
}

.form-select {
  font-size: 1.25rem;
  border-radius: 0;
  color: #737373;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0.55rem 1rem;
  font-weight: 600;
  background-size: 24px 22px;
  font-family: "Roboto Mono";
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-label {
  font-size: .875rem;
  color: #000;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
  font-weight: 500;
  font-family: 'Clancy';
}

.btn.load-more-btn {
  border: 2px solid #000;
  color: #171615;
  background-color: #ffdc00;
  width: 100%;
  border-radius: 0;
  padding: 1rem 0;
}

.btn.load-more-btn:hover,
.btn.load-more-btn:active,
.btn.load-more-btn:focus,
.btn.load-more-btn:focus-visible {
  background-color: #ffdc00;
  text-decoration: underline;
  color: #000;
  border-color: #000;
}

.btn.clear-filter-btn {
  width: auto;
  font-family: "Roboto Mono";
  font-weight: 600;
  color: #3f61c4;
  display: flex;
}

.btn.clear-filter-btn:after {
  content: url("./assets/gobackward-dark.svg");
  display: block;
  margin-left: 0.5rem;
  align-items: center;
}

.btn.clear-filter-btn:hover:after{
  -webkit-animation-name: spin;
  animation-name: spin;
  animation-direction: reverse;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.table {
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  width: 100%;
  border-width: 0;
}

.table thead tr th {
  font-family: 'Clancy';
  font-weight: 600;
}

.table td {
  padding: 1rem;
  border-color:  #e6e6e6;
}

.table tbody td:before {
  content: attr(data-th) ':';
  font-weight: 500;
  color: #000;
  display: none;
  margin-right: 1rem;
  min-width: 75px;
}

.table tbody tr:nth-of-type(1n) td {
  background-color: transparent;
  --bs-table-accent-bg: transparent;
}

.table tbody tr:nth-of-type(2n) td {
  background-color: #fafafa;
}

.table a {
  text-decoration: none;
}


@media (max-width: 992px) {
  .table thead {
    display: none;
  }

  .table tbody tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e6e6e6;
  }

  .table tbody tr td {
    display: flex;
    border-color: transparent;
  }

  .table tbody td:before {
    display: block;
  }
}

.table th.up {
  background-image: url("./assets/images/up_arrow.png");
}
.table th.down {
  background-image: url("./assets/images/down_arrow.png");
}
.table th.default {
  background-image: url("./assets/images/default.png");
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

#filterInformation {
  font-family: 'Clancy';
  font-size: .875rem;
  font-weight: 600;
  color: #4d4d4d;
}
